 // pose-detection supports 17 body parts:
    // 0'nose', 1'left_eye', 2'right_eye', 3'left_ear', 4'right_ear',
    // 5'left_shoulder', 6'right_shoulder', 7'left_elbow', 8'right_elbow',
    // 9'left_wrist', 10'right_wrist', 11'left_hip', 12'right_hip', 13'left_knee',
    // 14'right_knee', 15'left_ankle', 16'right_ankle'. This demo draws the first
    // five.
    let downRef=0;


export  function conta(poses) {
    if (poses[0]!=undefined){
        let pose=poses[0].keypoints;
        let left_shoulder=pose[5];
        let right_shoulder=pose[6];
        let leftEar=pose[3];
        let rightEar=pose[4];
        let leftKnee=pose[13];
        let rightKnee=pose[14];
        let leftHip=pose[15]; 
        let rightHip=pose[16];
        let allineamento=180-Math.abs(calcolaAngolo(left_shoulder.x,left_shoulder.y,right_shoulder.x,right_shoulder.y));
        return allineamento;
    }
    
  }

  function calcolaAngolo(x1, y1, x2, y2) {
    let dx = x2 - x1;
    let dy = y2 - y1;
    let angoloInRadianti = Math.atan2(dy, dx);
    let angoloInGradi = angoloInRadianti * (180 / Math.PI);
    return angoloInGradi; // L'angolo in gradi
}